<template>
    <div>
        <h2>{{ $t("invoice.titre_a_valider") }}</h2>
        <div class="box">

            <DateRangeV2
                v-if="start_date && end_date"
                :start.sync="start_date"
                :end.sync="end_date"
                @submit="loadAValider(start_date.toDateInputValue(), end_date.toDateInputValue())"
                :dateSelect="true"
                persistId="aValiderV2"
                :periodToShow="[ 'personalise', 'mois_dernier', 'mois']"
                periodLabel="mois"
            />
            <div class="row">
                <div class="col-2">
                    <b-form-checkbox
                        class="custom-control"
                        v-model="display_zero"  
                    >
                        {{ $t("invoice.afficher_montant_zero") }}
                    </b-form-checkbox>
                </div>
                <div class="col-4">
                    <b-form-checkbox
                        class="custom-control"
                        v-model="display_abandonne"  
                    >
                        {{ $t("invoice.afficher_abandonne") }}
                    </b-form-checkbox>
                </div>
            </div>

            <CustomTable
                :id_table="id_table"
                :items="a_valider"
                :busy.sync="table_busy"
                primaryKey="increment"
                :hide_if_empty="true"
                :hrefsRoutes="config_table_hrefs"
                :rawColumns="rawColumnNames"
                :callback_one="pension_checkall"
                :callback_one_deselect="pension_decheckall"
				:externSlotColumns="extern_slot_columns"
                ref="aValiderTable"
            >
                <template v-slot:[`custom-slot-cell(pourcentage)`]="{ data }">
					{{ data.pourcentage }}%
				</template>
            </CustomTable>
        </div>

        <b-modal
            ref="processing_modal"
            :title="$t('invoice.processing_valid')"
            hide-footer
        >
            <b-progress show-progress height="2rem" :max="total">
                <b-progress-bar :value="processed" :label="`${((processed / total) * 100).toFixed(0)}%`"></b-progress-bar>
            </b-progress>
            <h5 class="text-center mt-2">
                {{ $t('invoice.total_valid', {total, processed}) }}
            </h5>
        </b-modal>
    </div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'

    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Config from "@/mixins/Config.js"
    import Vue from 'vue'

    export default {
        name: "avalider",
        mixins: [Config, TableAction, Invoice, Navigation],
        props: {
            options: {
                type: Object,
                default: () => ({
                    type: 'Invoice',
                    id: null,
                })
            }
        },
        data () {
            return {
				id_table: "facturation_a_valider",
                a_valider: null,
                display_zero: false,
                display_abandonne: false,
                processing: false,
                table_busy: true,
                start_date: null,
                end_date: null,
				extern_slot_columns: ['pourcentage'],
                events_tab: {
                    'TableAction::goToValideFacturationAValider': this.valideFacturationAValider,
                    'TableAction::goToAbandonFacturationAValider': this.goToAbandonAValider,
                },
                rawColumnNames: ['horse_nom'],
                config_table_hrefs: {
                    'horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    },
                    'author_rs': {
                        routeUniqueName: 'tiersTableauBord',
                        routeName: 'tiersTableauBord',
                        params: {
                            tiers_id: 'author_id'
                        }
                    }
                },
                total: 0,
                processed: 0
            }
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                if(this.getConfig('startDateAValider')) {
                    this.start_date = new Date(this.getConfig('startDateAValider'))
                }else {
                    this.start_date = new Date(new Date().setMonth(new Date().getMonth() - 1))
                }
                if(this.getConfig('endDateAValider')) {
                    this.end_date = new Date(this.getConfig('endDateAValider'))
                } else {
                    this.end_date = new Date()
                }
                // this.loadAValider(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
            },
            async loadAValider(start_date, end_date) {
                this.table_busy = true
				this.saveDateFilters()

                const a_valider = await this.loadFacturationAValider(start_date, end_date, this.display_zero, this.display_abandonne)
                this.a_valider = await this.formatAValider(a_valider)

				this.table_busy = false
            },
			async formatAValider(a_valider) {
				let avalider = []
				if(!a_valider) {
					return []
				}
				let increment = 0

				if(a_valider.actes) {
					for(let i = 0; i < a_valider.actes.length; i++) {
						increment ++
						let temp = a_valider.actes[i]
						temp.increment = increment
						temp.href = "horseFiche"
						temp.params = { horse_id: temp.horse_id }

                        // Date globale pour l'enregistrer dans le même format pour tout le monde
                        temp.global_date = new Date(temp.actestype_label).toLocaleDateString('en')
                        // temp.actestype_label = temp.actestype_label ? new Date(temp.actestype_label).toLocaleDateString(Vue.prototype.$i18n.locale()) : ''
                        
                        // const date = temp.actestype_labeldate.split(' | ')[1] ? new Date(temp.actes_date).toLocaleDateString(Vue.prototype.$i18n.locale()) : ''
                        // temp.actestype_labeldate = temp.actestype_labeldate.split(' | ')[0] + ' | ' + date

						temp.ht_no_format = temp.ht
                        temp.ht_unit_no_format = temp.ht_unit
						temp.ht = await this.priceFormat(temp.ht, temp.author_currency)
						avalider.push(temp)
					}
				}

				if(a_valider.pension) {
					for(let i = 0; i < a_valider.pension.length; i++) {
						increment ++
						let temp = a_valider.pension[i]

                        // Date globale pour l'enregistrer dans le même format pour tout le monde
                        temp.global_date = new Date(temp.date_debut).toLocaleDateString('en') + ' - ' + new Date(temp.date_fin).toLocaleDateString('en')

                        const debut = new Date(temp.date_debut).toLocaleDateString(Vue.prototype.$i18n.locale())
                        const fin = new Date(temp.date_fin).toLocaleDateString(Vue.prototype.$i18n.locale())

						temp.pension_label = temp.date_debut_format + " - " + temp.date_fin_format
						temp.increment = increment

						temp.href = "horseFiche"
						temp.params = { horse_id: temp.horse_id }

						temp.ht_no_format = temp.ht
						temp.ht = await this.priceFormat(temp.ht, temp.author_currency)
						avalider.push(temp)
					}
				}

                if(a_valider.contracts) {
                    for(let i = 0; i < a_valider.contracts.length; i++) {
                        increment ++
                        let temp = a_valider.contracts[i]

                        temp.contract_label = temp.articles_label
                        temp.increment = increment

                        temp.href = "horseFiche"
                        temp.params = { horse_id: temp.horse_id }

                        temp.ht_no_format = temp.ht
                        temp.ht = await this.priceFormat(temp.ht, temp.author_currency)
                        avalider.push(temp)
                    }
                }

				return avalider
			},
            async valideFacturationAValider(horse_actes) {
                if(!this.processing)
                {
                    this.processing = true

                    let paramsToSend = {
                        'details': [],
                        'pensions': []
                    }
                    let result_invoice_details = false
                    let max_processing = 50

                    if(horse_actes) {
                        this.total = horse_actes.length
                        if(this.total > max_processing) {
                            this.$refs.processing_modal.show()
                        }
                        for(let i = 0; i < horse_actes.length; i++) {
                            if(horse_actes[i].pension_id != undefined) {
                                let ht_initial = horse_actes[i].ht_initial
                                // if(horse_actes[i].discount_type && horse_actes[i].discount_type.invoicediscounttype_type == "numeraire") {
                                //     ht_initial -= horse_actes[i].discount_value*100
                                // }
                                // else if(horse_actes[i].discount_type && horse_actes[i].discount_type.invoicediscounttype_type == "pourcentage") {
                                //     ht_initial -= ht_initial*(horse_actes[i].discount_value/100)
                                // }

                                let pension_qte = ''
                                if(horse_actes[i].monthly == 1 && horse_actes[i].prorata == 1) {
                                    pension_qte = horse_actes[i].nb_jour+'/'+horse_actes[i].nb_jour_mois
                                }

                                let ht_unitaire = ht_initial*horse_actes[i].pourcentage/100
                                ht_unitaire = Math.trunc(ht_unitaire)
                                
                                paramsToSend.pensions.push({
                                    author_id: horse_actes[i].author_id,
                                    tiers_id: horse_actes[i].tiers_id,
                                    label: horse_actes[i].pension_label,
                                    qte: horse_actes[i].pension_qte,
                                    htunit: ht_unitaire,
                                    vat: horse_actes[i].vat,
                                    horse_id: horse_actes[i].horse_id,
                                    articles_id: horse_actes[i].articles_id,
                                    pension_id: horse_actes[i].pension_id,
                                    horsepension_id: horse_actes[i].horsepension_id,
                                    start: horse_actes[i].date_debut,
                                    end: horse_actes[i].date_fin,
                                    description: '',
                                    tiershorseparttype: 2,
                                    tiershorsepartpart: horse_actes[i].pourcentage,
                                    discount_type: horse_actes[i].discount_type,
                                    discount_value: horse_actes[i].discount_value,
                                    ht_initial: ht_initial,
                                    pension_qte: pension_qte
                                })
                            }
                            else {
                                paramsToSend.details.push({
                                    author_id: horse_actes[i].author_id,
                                    tiers_id: horse_actes[i].tiers_id,
                                    label: horse_actes[i].actestype_label,
                                    qte: horse_actes[i].qte,
                                    htunit: horse_actes[i].ht_unit_no_format,
                                    vat: horse_actes[i].vat,
                                    horse_id: horse_actes[i].horse_id,
                                    articles_id: horse_actes[i].articles_id,
                                    actes_id: horse_actes[i].actes_id,
                                    description: horse_actes[i].actesarticles_commentaire,
                                    tiershorseparttype: 1,
                                    tiershorsepartpart: horse_actes[i].tiershorsepart_frais,
                                    discount_type: null,
                                    discount_value: null,
                                    ht_initial: horse_actes[i].ht_initial
                                })
                            }

                            if(((i+1)%max_processing == 0 || (i+1) == this.total) && paramsToSend) {
                                result_invoice_details = await this.createInvoiceDetails(paramsToSend)
                                this.processed = i+1
                                paramsToSend = {
                                    'details': [],
                                    'pensions': []
                                }
                            }
                        }
                    }

                    this.$refs.processing_modal.hide()
                    EventBus.$emit("TableAction::stopSpin")

                    if(result_invoice_details) {
                        this.$router.push({name: 'invoiceBons'})
                    }
                    
                    this.processing = false
                }
            },
            async goToAbandonAValider(horse_actes) {
                if(!this.processing)
                {
                    this.processing = true
                    let paramsToSend = {};
                    paramsToSend['details'] = []
                    paramsToSend['pensions'] = []

                    if(horse_actes) {
                        for(let i = 0; i < horse_actes.length; i++) {
                            if(horse_actes[i].pension_id != undefined) {
                                paramsToSend.pensions.push({
                                    tiers_id: horse_actes[i].tiers_id,
                                    horse_id: horse_actes[i].horse_id,
                                    horsepension_id: horse_actes[i].horsepension_id,
                                    start: horse_actes[i].date_debut,
                                    end: horse_actes[i].date_fin,
                                })
                            }
                            else {
                                paramsToSend.details.push(
                                    horse_actes[i].actes_id,
                                )
                            }
                        }
                        paramsToSend.details = paramsToSend.details.toString()
                    }

                    if(paramsToSend) {
                        await this.abandonAValider(paramsToSend)
                    }
                    this.$refs.aValiderTable.unselectAll()
                    this.loadAValider(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
                    
                    EventBus.$emit("TableAction::stopSpin")
                    this.processing = false
                    
                }
            },

            pension_checkall(item) {
                if(item.length) {
					const horse_id = item[0].horse_id
					const tiers_id = item[0].tiers_id

                    if(item[0].horsepension_id != undefined) {
                        //Je vais regarder toutes les pensions id qui vont ensembles sur le même cheval et même tiers pour toutes les facturer en même temps
                        let horsepension_id = item[0].horsepension_id
                        let date_debut = item[0].date_debut
                        let date_fin = item[0].date_fin

                        let all_same_pensions = this.a_valider.filter(pension => pension.horsepension_id == horsepension_id && pension.horse_id == horse_id && pension.tiers_id == tiers_id && pension.date_debut == date_debut && pension.date_fin == date_fin )

                        this.$refs.aValiderTable.manual_check(all_same_pensions)
                    }
					else if(item[0].actes_id != undefined) {
						const actes_id = item[0].actes_id
						const same_acte = this.a_valider.filter(acte => acte.actes_id === actes_id && acte.horse_id == horse_id && acte.tiers_id == tiers_id)

						this.$refs.aValiderTable.manual_check(same_acte)
					}
				}
            },

            pension_decheckall(item) {
                if(item.length) {
                    if(item[0].horsepension_id != undefined) {
                        let horsepension_id = item[0].horsepension_id
                        let horse_id = item[0].horse_id
                        let tiers_id = item[0].tiers_id
                        let date_debut = item[0].date_debut
                        let date_fin = item[0].date_fin

                        let all_same_pensions = this.a_valider.filter(pension => pension.horsepension_id == horsepension_id && pension.horse_id == horse_id && pension.tiers_id == tiers_id && pension.date_debut == date_debut && pension.date_fin == date_fin ) 
                        this.$refs.aValiderTable.manual_decheck(all_same_pensions)
                    }
					else if(item[0].actes_id != undefined) {
						const same_acte = this.a_valider.filter(acte => acte.actes_id === item[0].actes_id)

						this.$refs.aValiderTable.manual_decheck(same_acte)
					}
				}
			},
			
			saveDateFilters() {
                this.setConfig('startDateAValider', this.start_date)
                this.setConfig('endDateAValider', this.end_date)
			},

            async display_current_month() {
                const now = new Date();
                this.start_date = new Date(now.getFullYear(), now.getMonth(), 1)
                this.end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0)

                await this.loadAValider(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
            },

            async display_previous_month() {
                let date = new Date()
                date.setMonth(date.getMonth() - 1)
                this.start_date = new Date(date.getFullYear(), date.getMonth(), 1)
                this.end_date = new Date(date.getFullYear(), date.getMonth() + 1, 0)


                await this.loadAValider(this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
            },
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
        }
    }
</script>
